import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import Style from "styles/components/comachicart/maintenance.module.scss";

const Maintenance = () => {
  return (
    <section className={Style.maintenance}>
      <h2 className={classNames(Style.catch)}>
        <span>２要素認証マニュアル</span>
      </h2>
      <p className={classNames(Style.maintenance__notice, Style.mt40, Style.mb40)}>
        <div>平素より大変お世話になっております。 comachicart運営チームでございます。</div>
        <p>２要素認証の手順のリンクはこちらになります。</p>
        <div>
          <a style={{ color: "blue" }} href={process.env.AUTH_MANUAL}>
            comachicart_店舗側ログインフロー
          </a>
        </div>
        <div style={{ marginTop: "20px" }}>comachicart運営チーム</div>
      </p>
    </section>
  );
};
export default Maintenance;
