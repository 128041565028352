import Layout from 'components/comachicart/Layout';
import AuthManual from 'components/comachicart/AuthManual';
import SEO from 'components/seo';
import React from 'react';

const ReleacePage = () => {
  return (
    <Layout>
      <SEO title='comachicart' path='comachicart/2auth-manual' />
      <AuthManual />
    </Layout>
  );
};

export default ReleacePage;
